import * as React from 'react';
import PageTemplate from '../components/pageTemplate'
import {LocationContext} from "../routes";
import Link from "../components/link";
import {useIntl} from "gatsby-plugin-intl";

const Imprint = ({location}) => {
  const intl = useIntl();
  return <LocationContext.Provider value={location}>
    <PageTemplate title="">
      <h3 style={{color: "#558335"}}>Copyright</h3>
      <p>
        <b>Wegener Center for Climate and Global Change</b>
        <br/><b>University of Graz</b>
        <br/>Brandhofgasse 5
        <br/>8010 Graz, Austria
        <br/>
        <br/>Web: <Link href={"https://wegcenter.uni-graz.at"}>https://wegcenter.uni-graz.at</Link>
        <br/>Tel.: +43-316-380 8470
        <br/>Mail: <Link href={"mailto:wegcenter@uni-graz.at"}>wegcenter@uni-graz.at</Link>
      </p>
      {intl.locale === "de" ? <DeImprint/> : <EnImprint/>}
    </PageTemplate>
  </LocationContext.Provider>
};

const EnImprint: React.FC = () => {
  return (<>
    <h3 style={{color: "#558335"}}>Terms of Service</h3>
    <div style={{fontSize: "large", color: "#666666"}}>(c) {new Date().getFullYear()} Wegener Center, University of Graz, Austria</div>
    <p>
      <br/>The usage of texts, images, tables, and any other data and tools from this website is permitted under the applicable <Link href={"https://creativecommons.org/licenses/by-nc/4.0/deed.en"}>CC BY-NC 4.0 license</Link> for non-commercial purposes if appropriate credit to the source is given (suggested shortest form: “GCCI UniGraz {new Date().getFullYear()}”; in case of further use of data for your own purposes, a suitable specific/scientific citation of the respective data sources/references is required). For any other purposes, please contact: wegcenter@uni-graz.at
    </p>
    <h3 style={{color: "#558335"}}>Disclaimer</h3>
    <p>
      The information on this website (texts, images, tables, and any other data and tools) is provided by expert staff of the Wegener Center, aiming to meet the highest scientific and technical standards. Additionally, the information is continuously reviewed and updated.
    </p>
    <p>
      Despite the utmost care, errors and deficiencies may occur or information may be outdated. Therefore, we cannot assume any liability or warranty for the topicality, accuracy or integrity of the information provided. The Wegener Center reserves the right to change, supplement or remove information, without advance notice, anytime.
    </p>
    <p>
      We cannot assume any liability for any direct or indirect damages that may arise from the use of the contents of this website.
    </p>
    <p>
      Furthermore, the Wegener Center excludes any responsibility or liability for contents of web pages to which Links are provided, or for web pages where Links to web pages of this website can be found.
    </p>
  </>);
}

const DeImprint: React.FC = () => {
  return (<>
    <h3 style={{color: "#558335"}}>Nutzungsbedingungen</h3>
    <div style={{fontSize: "large", color: "#666666"}}>(c) {new Date().getFullYear()} Wegener Center, University of Graz, Austria</div>
    <p>
      <br/>Die Verwendung von Texten, Bildern, Tabellen oder jeglichen anderen Daten und Tools von dieser Website ist unter der geltenden <Link href={"https://creativecommons.org/licenses/by-nc/4.0/deed.de"}>CC BY-NC 4.0 Lizenz</Link> für nicht-kommerzielle Zwecke gestattet, sofern die Quelle korrekt angegeben wird (Vorschlag für die kürzeste Form: „GCCI UniGraz {new Date().getFullYear()}“; für eigene weiter-verarbeitende Datennutzung ist eine spezifische/wissenschaftliche Zitierung der konkreten Datenquellen/Referenzen erforderlich). Für alle anderen Zwecke kontaktieren Sie bitte: wegcenter@uni-graz.at
    </p>
    <h3 style={{color: "#558335"}}>Benutzungshinweise und Haftungsausschluss</h3>
    <p>
      Die Informationen dieser Website (Texte, Bilder, Tabellen, jegliche weitere Daten und Tools) werden von den MitarbeiterInnen des Wegener Centers auf höchstem wissenschaftlichen und technischen Standard erstellt. Auch werden die Informationen laufend geprüft und aktualisiert.      </p>
    <p>
      Trotz aller Sorgfalt können Fehler vorkommen oder die Daten teilweise nicht mehr aktuell sein. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen kann daher nicht übernommen werden. Das Wegener Center behält sich auch vor, Informationen jederzeit und ohne Vorankündigung zu ändern, zu ergänzen oder zu entfernen.      </p>
    <p>
      Für allfällige Schäden jedweder Art, direkt oder indirekt, die sich aus der Verwendung der Inhalte der Website ergeben, wird keine Haftung übernommen.      </p>
    <p>
      Weiters schließt das Wegener Center jede Verantwortung oder Haftung für Inhalte von Internetseiten aus, auf die mit einem Link verwiesen wird, ebenso wie für Seiten, von denen auf diese Website verwiesen wird.      </p>
  </>);
}

export default Imprint;
